<template lang="pug">
  div
    base-snackbar(
      :errors.sync="customerGetErrors"
    )
    v-data-table(
      :items="customerList"
      :headers="headers"
      dense
      :loading="customerGetting"
      :server-items-length="serverItemsLength"
      single-expand
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
    )
      template(v-slot:top)
        tr
          td(:colspan="headers.length")
              v-text-field(
                label="search"
                append-icon="mdi-magnify"
                v-model="search"
              )
      template(v-slot:item="{ item, expand, isExpanded }")
        tr
          td {{ item.id }}
          td {{ $autoAbbriviate(item.company) }}
          td {{ item.invoices_count }}
          td
            v-btn(
              :class="isExpanded ? 'is-expanded' : ''"
              text
              small
              color="blue-grey darken-3"
              @click="expand(!isExpanded)"
            )
              v-icon mdi-chevron-down
      template(v-slot:expanded-item="{ item }")
        tr
          td(colspan="4")
            v-row(justify="center")
              v-col.text-left.grey--text(cols="3")
                small.text-uppercase Stock
              v-col.text-center.grey--text(cols="3")
                small.text-uppercase Quantity
              v-col.text-right.grey--text(cols="3")
                small.text-uppercase Amount
            v-divider
            div(v-for="(detail, index) in item.order_details" :key="index")
              v-row(justify="center")
                v-col.text-left.blue-grey--text.darken-4(cols="3")
                  div
                    small {{ detail.stock.description }}
                v-col.blue-grey--text.darken-4(cols="3")
                  div.text-right.pr-2
                    small {{ getQuantity(detail) }}
                v-col.text-right.blue-grey--text.darken-4(cols="3")
                  small {{ (detail.amount || 0).phpFormat() }}
              v-divider
            v-row(justify="center")
              v-col.text-right.grey--text(cols="6")
                div.pr-2
                  small.text-uppercase Total
              v-col.text-right.blue-grey--text(cols="3")
                small {{ calculateSumOfAmount(item.order_details).phpFormat() }}
</template>
<style lang="scss" scope>
  .v-btn.is-expanded .v-icon {
    transform: rotate(-180deg);
  }
  .border-right {
    border-right: 1px solid #E0E0E0;
  }
</style>
<script>
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'
import customerRepository from '@/repositories/customer.repository'

const customerGetRequestHandler = new VueRequestHandler(null, requestVars({ identifier: 'customer' }).last())

const tableVars = () => ({
  headers: [
    { text: 'ID', value: 'id', sortable: false },
    { text: 'Company', value: 'company', sortable: false },
    { text: 'Orders', value: 'invoices_count', sortable: false },
    { text: 'Config', sortable: false },
  ],
  search: null,
  page: 1,
  itemsPerPage: 10,
  serverItemsLength: 0,
})

export default {
  name: 'CustomerOrderTable',
  created () {
    this.getCustomer()
  },
  data: () => ({
    ...tableVars(),
    ...requestVars({ identifier: 'customer' }).first(),
  }),
  watch: {
    search () {
      this.getCustomer()
    },
    page () {
      this.getCustomer()
    },
    itemsPerPage () {
      this.getCustomer()
    },
  },
  methods: {
    getCustomer () {
      const handler = customerGetRequestHandler
      const repository = customerRepository.orderDetails
      const params = this.getGetCustomerParams()
      handler.setVue(this)
      handler.execute(repository, [params], this.getCustomerStatusOk)
    },
    getCustomerStatusOk ({ data }) {
      this.serverItemsLength = data.count
      this.customerList = data.list
    },
    getGetCustomerParams () {
      const { search, page, itemsPerPage } = this
      const offset = itemsPerPage * (page - 1)
      return { search, offset, limit: itemsPerPage }
    },
    calculateSumOfAmount (items) {
      return items.reduce((total, item) => total + item.amount, 0)
    },
    getQuantity (stock) {
      const units = ['bag', 'kg', 'egg'].filter(key => stock[key])
      return units.map(key => {
        if (stock[key] > 1) return `${stock[key]} ${key}s`
        return `${stock[key]} ${key}`
      }).join(' & ')
    },
  },
}
</script>